// Charge la base commune
import './common.js'

// Styles custom
import './scss/pages/blog'

// Scripts librairies
/* global Waypoint */
import 'waypoints/lib/jquery.waypoints.min.js'

// rafraîchissement
function r () {
  Waypoint.refreshAll()
}

// OwlCarousel
async function getOwlCarousel () {
  import(/* webpackChunkName: "owl-carousel" */ './js/components/owl-carousel.js').then(() => {
    r()
  })
}

// Pajination
async function getPajination () {
  import(/* webpackChunkName: "pajination", webpackPrefetch: true */ './js/components/pajination.js').then(() => {
    r()
    // sur changement de page, on se repositionne sur le début de listing
    $('ul.pagination > li > a').on('click', function () {
      $('html,body').animate({ scrollTop: $('#blog').offset().top - 100 }, 300)
      r()
    })
  })
}

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion des carousel
  const $d = $('.owl-carousel.c')
  if ($d.length) {
    $d.waypoint(function () {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion des pages
  const $p = $('.pajinate')
  if ($p.length) {
    $p.waypoint(function () {
      getPajination()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // gestion cta
  const $cta = $('.cta')
  if ($cta.length) {
    $cta.waypoint(function () {
      import(/* webpackChunkName: "cta" */ './scss/components/cta.scss').then(() => {
        Waypoint.refreshAll()
      })
      this.destroy()
    }, {
      offset: '100%'
    })
  }

  // refresh waypoints au chargement d'une image
  $('.lazyload').on('lazyloaded', () => {
    r()
  })

  if ($('#go').length) {
    const t = $('#go')
    $('html, body').animate({
      scrollTop: ($(t).offset().top - $('#header').height() - 50)
    }, 300)
  }
})
